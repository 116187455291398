<template>
  <CContainer>
    <nav-bar v-bind:headerName="headerName"></nav-bar>
    <hr class="mt-0" />

    <div class="row">
      <div class="col">
        <div
          class="card border text-center"
          style="padding: 20px"
          @click="internetbanking('internet_banking_bay')"
        >
          <h6>
            <i class="bank bank-bay xxxl"></i>
          </h6>
          <small>ธนาคารกรุงศรีอยุธยา</small>
        </div>
      </div>
      <div class="col">
        <div
          class="card border text-center"
          style="padding: 20px"
          @click="internetbanking('internet_banking_bbl')"
        >
          <h6>
            <i class="bank bank-bbl xxxl"></i>
          </h6>
          <small>ธนาคารกรุงเทพ</small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div
          class="card border text-center"
          style="padding: 20px"
          @click="internetbanking('internet_banking_scb')"
        >
          <h6>
            <i class="bank bank-scb xxxl"></i>
          </h6>
          <small> ธนาคารไทยพาณิชย์</small>
        </div>
      </div>
      <div class="col">
        <div
          class="card border text-center"
          style="padding: 20px"
          @click="internetbanking('internet_banking_ktb')"
        >
          <h6>
            <i class="bank bank-ktb xxxl"></i>
          </h6>
          <small>ธนาคารกรุงไทย</small>
        </div>
      </div>
    </div>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '../menu/NavBar'
import payment from '@/services/payment'
import '@/assets/css/banks.css'

export default {
  data() {
    return {
      headerName: 'อินเตอร์เน็ตแบงก์กิ้ง',
    }
  },
  components: {
    NavBar,
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopLineObjectId
      )
    },
    shopName() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].shopName
      } else {
        return ''
      }
    },
  },
  methods: {
    internetbanking(sourceType) {
      let amount = sessionStorage.getItem('amount')
      let totalAmount = Number(amount) * 100
      let data = { sourceType: sourceType, amount: totalAmount }

      payment({
        url: '/omise/payment/v1.0/test/internetbanking',
        data: data,
        method: 'post',
      }).then((res) => {
        if (res.data.error.code === 0) {
          sessionStorage.setItem('chargeId', res.data.data.id)
          window.location.replace(res.data.data.uri)
        }
        console.log(res)
      })
    },
  },
}
</script>
